'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Toggle from '@radix-ui/react-toggle';
import { cloneElement } from 'react';
import { CrossLine } from '@zealy/icons';
import { cn } from '../../utils/cn';
import { preventPropagation } from '../../utils/events';
import { TagContextProvider, useTagContext } from './Tag.context';
import { tagStyles as styles } from './Tag.styles';
const Icon = (props) => {
    const context = useTagContext();
    const { icon, size = context.size, variant } = props;
    return icon
        ? cloneElement(icon, {
            className: cn(icon.props?.className, styles.icon({ size, variant })),
        })
        : null;
};
function CheckboxIcon({ className = '' }) {
    return (_jsx("svg", { className: className, width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", role: "presentation", children: _jsx("path", { d: "M4 13L9 18L20 6", stroke: "currentColor", strokeWidth: 4, strokeLinecap: "round", strokeLinejoin: "round" }) }));
}
const LeftIcon = (props) => {
    const context = useTagContext();
    const { tagType = context.tagType, size = context.size, variant, ...rest } = props;
    if (tagType !== 'checkbox' && tagType !== 'radio')
        return _jsx(Icon, { ...props });
    return (_jsx("div", { className: styles.controlContainer({ size }), children: _jsx("div", { className: styles[tagType].root({}), ...rest, children: _jsx("div", { className: styles[tagType].indicator(), children: tagType === 'checkbox' && (_jsx(CheckboxIcon, { className: 'w-full h-full group-data-[state=on]:opacity-100 opacity-0' })) }) }) }));
};
const CloseButton = (props) => {
    const context = useTagContext();
    const { icon, size = context.size, disabled = context.disabled, onRemove, className } = props;
    const onRemoveClick = preventPropagation(() => {
        onRemove?.();
    }, true);
    return (_jsx("button", { type: "button", "aria-label": "Remove", disabled: disabled, className: cn(styles.closeButton({
            size,
        }), className), onClick: onRemoveClick, children: _jsx(Icon, { icon: icon || _jsx(CrossLine, {}) }) }));
};
const Label = (props) => {
    const context = useTagContext();
    const { children, size = context.size, className, wrapText, variant, ...rest } = props;
    return (_jsx("span", { className: cn(styles.tagLabel({ size, wrapText, variant }), className), ...rest, children: children }));
};
const Image = (props) => {
    const { src, label, className, ...rest } = props;
    return (_jsx("img", { ...rest, "aria-label": label, src: src, "aria-labelledby": label, alt: label, className: cn(styles.tagImage({}), className) }));
};
const Root = ({ children, size, variant, as, disabled, selected, className, image, type, ...props }) => {
    const interactive = isTokenInteractive({ as, ...props });
    const Component = interactive && !as ? Toggle.Root : as || 'span';
    const context = { tagType: type, size, disabled };
    return (_jsx(TagContextProvider, { value: context, children: _jsxs(Component, { disabled: disabled, "aria-disabled": disabled, className: cn(styles.tagRoot({
                size,
                variant,
                interactive,
            }), className), ...props, children: [_jsx("div", { className: cn(styles.tagRow({ size }), type ? 'items-start' : 'items-center'), children: children }), image && _jsx(Image, { src: image, label: typeof children === 'string' ? children : undefined })] }) }));
};
export const Tag = ({ children, onRemove, leftIcon, rightIcon, as, wrapText, ...props }) => {
    return (_jsxs(Root, { as: as, ...props, children: [_jsx(LeftIcon, { icon: leftIcon, checked: props.selected, variant: props.variant }), children && (_jsx(Label, { variant: props.variant, wrapText: wrapText, children: children })), onRemove && _jsx(CloseButton, { onRemove: onRemove, icon: rightIcon })] }));
};
// we don't know the allowed props when using 'as'
const isTokenInteractive = ({ as = 'span', onClick, onFocus, tabIndex = -1, href }) => Boolean(href || onFocus || onClick || tabIndex > -1 || ['a', 'button'].includes(as));
export const TagAnatomy = {
    Root,
    Label,
    Image,
    LeftIcon,
    CloseButton,
};
